import { css } from 'astroturf';
import React, { Component } from 'react';
import Link from './link';

const styles = css`
  .wrap {
    @media (min-width: 30rem) and (min-height: 45rem) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .visible {
    opacity: 1;
    pointer-events: all;
  }
  .contactForm {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    max-width: 34rem;
    width: 100%;
    text-align: left;
    margin-top: 2rem;
    padding: 4rem 2rem;

    & > div {
      position: relative;
      width: 100%;
      margin: 0 auto;

      & .error {
        color: red;
        margin-top: 1rem;
        font-weight: bold;
      }
    }

    & h3,
    & legend {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 700;
      margin-bottom:1rem;
    }

    & label {
      margin: 2rem 0 0.25rem;
    }

    & textarea {
      font-size: 1rem;
      padding: 1rem;
      border-radius: 0.5rem;
      border: 1px solid rgba(0, 70, 150, 0.2);
      width: 100%;
      height: 4rem;
    }

    & div button {
      float: right;
      clear: right;
      border: 0;
      border-radius: 0.5rem;
      padding: 0 1rem;
      line-height: 2rem;
      background: #107db5;
      font-weight: 600;
      font-size: 1.17rem;
      color: #fff;
      transition: 0.25s ease-in-out background;
      flex-shrink: 0;

      &:hocus {
        background: #67b26f;
      }
    }

    & small {
      opacity: 0.75;
      float: right;
      clear: right;
    }
  }

  .box {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    & p {
      font-size:0.9rem;
      font-style: italic;
    }

  }
`;

class Questionaire extends Component {
  constructor() {
    super();

    this.state = {
      mailSent: false,
      message: '',
      status: '',
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const why = e.target.elements[0].value;
    const hesitation = e.target.elements[1].value;
    const link = 'https://hooks.zapier.com/hooks/catch/2447005/7twomm/';
    const data = {
      why,
      hesitation,
    };
    const result = await fetch(link, { method: 'POST', body: JSON.stringify(data) });

    if (result.ok) {
      this.setState({
        mailSent: true,
        status: 'ok',
        message: `<h3>Thank you for your feedback</h3><p style="text-align:center">It'll help us make Polypane even better for you.</p>`,
      });
    } else {
      this.setState({
        mailSent: false,
        status: 'error',
        message: `<h3>Request not sent!</h3><p style="text-align:center">There was an error sending this form. please try again later.</p>`,
      });
    }
  };

  resetForm = () => {
    this.setState({
      mailSent: false,
      status: '',
      message: '',
    });
  };

  hideForm = () => {
    this.props.setVisible(false);

    setTimeout(() => {
      this.resetForm();
    }, 500);
  };

  preventPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { mailSent, status, message } = this.state;

    return (
      <div className={[styles.wrap, styles.visible].join(' ')}>
        <form className={styles.contactForm} onSubmit={this.handleSubmit}>
          {mailSent && status === 'ok' && <div dangerouslySetInnerHTML={{ __html: message }} />}
          {!mailSent && (
            <div>
              <legend>Can we ask you two questions?</legend>
              <label htmlFor="why">Why did you subscribe to Polypane?</label>
              <textarea name="why" id="why" onFocus={this.resetForm} />

              <label htmlFor="hesitation">Was there anything that almost stopped you from subscribing?</label>
              <textarea name="hesitation" id="hesitation" onFocus={this.resetForm} />

              <div className={styles.box}>
                {status === 'error' ? (
                  <p className={styles.error} dangerouslySetInnerHTML={{ __html: message }} />
                ) : (
                  <p>
                    All responses are <b>anonymous</b>. If you want assistance with anything,{' '}
                    <Link to="/support/">contact us directly</Link>.
                  </p>
                )}
                <button type="submit">Send</button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default Questionaire;
