import { css } from 'astroturf';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { Helmet } from 'react-helmet';

import PageHeader from '../components/PageHeader';
import Questionaire from '../components/Questionaire';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .downloadPage {
    text-align: center;
    padding-bottom: 6rem;

    & > p {
      padding: 0 1rem;

      & + p {
        margin-top: 2rem;
      }
    }
  }
  .nextup {
    max-width: 32rem;
    margin: 0 auto;
    padding: 2rem;
    overflow: hidden;
    background: #fffa;
    box-shadow: inset 0px 0px 6px #0132;
    border-radius: 0.5rem;
    border:1px solid #0001;

    & p {
      margin-bottom: 1rem;
    }

    & a {
      margin: 0;
      padding: 0;
      background: 0;

      & > span {
        width: 120px;
        display: block;
        margin: 0.25rem auto 0;
        background: rgb(29, 155, 240);
        color: #fff;
        font-weight: bold;
        border-radius: 0.5rem;
        padding: 0.25rem 0.5rem;
        float: right;
      }

      &:hocus > span {
        background: rgb(26, 140, 216);
      }
    }

    & small {
      line-height: 32px;
      opacity: 0.75;
      float: right;
      margin-top: 0.25rem;
      margin-right: 0.5rem;
    }
  }

  .confetticontainer {
    width: 100%;
    max-width: 840px;
    display: flex;
    justify-content: space-between;
    height: 0;
    margin: 0 auto;
    transform: translateY(-100px);

    @media (prefers-reduced-motion: reduce) {
      display: none;
    }
  }

  .tweetholder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    & svg {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      fill: transparent;
      stroke: hsl(204, 88%, 53%);
      stroke-width: 2px;
    }

    & div {
      position: relative;
      background: #fff;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
      text-align: left;
      color: #000;

      &::before {
        content: ' ';
        position: absolute;
        left: -0.5rem;
        bottom: calc(50% - 0.5rem);
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-right: 0.5rem solid white;
        border-bottom: 0.5rem solid transparent;
      }

      & span {
        color: hsl(204, 88%, 53%);
      }
    }
  }
`;

const config = {
  angle: 100,
  spread: 120,
  startVelocity: '40',
  elementCount: '150',
  dragFriction: '0.1',
  duration: 9000,
  stagger: '3',
  width: '16px',
  height: '16px',
  colors: ['#b51010', '#b5107d', '#107db5', '#10b57d', '#7d10b5', '#7db510'],
  // colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const Page = (props) => {
  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    setConfetti(true);
  }, []);
  return (
    <Layout>
      <SEO title="Thanks for subscribing" keywords={['']} pathname={props.location.pathname} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.confetticontainer}>
        <Confetti active={confetti} config={config} />
        <Confetti active={confetti} config={config} />
      </div>
      <PageHeader>
        <h1>Thank you for subscribing! 🎉️</h1>
        <p>
          You can manage your subscription and account in the <Link to="https://dashboard.polypane.app">Dashboard</Link>
          .
        </p>
      </PageHeader>

      <div className={styles.downloadPage}>
        <div className={styles.nextup}>
          <h2>Share on X/Twitter</h2>
          <p>Celebrate getting a subscription by sharing it out on Twitter, it really helps get the word out! ❤️</p>

          <a href="https://twitter.com/intent/tweet?related=polypane&text=Just%20improved%20my%20web%20dev%20workflow%20by%20getting%20a%20%40polypane%20subscription!%20%F0%9F%A5%B3%20%F0%9F%94%A5%20%F0%9F%A4%A9&url=https%3A%2F%2Fpolypane.app&original_referer=https://polypane.app/thanks/">
            <div className={styles.tweetholder}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
              </svg>
              <div>
                Just improved my web dev workflow by getting a <span>@polypane</span> subscription! 🥳 🔥 🤩
              </div>
            </div>
            <span>Post this</span>
          </a>
          <small>You can change the message before posting</small>
        </div>
        <script async src="https://platform.twitter.com/widgets.js" />
        <Questionaire />
      </div>
    </Layout>
  );
};

export default Page;
